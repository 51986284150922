<!--
 * @Author: Link
 * @Date: 2021-08-20 20:46:03
 * @LastEditTime: 2021-08-31 17:11:47
-->
<template>
  <div class="todo">
    <div class="header">
      <img class="shape" src="@/asset/icon/overview/shape1.png" />
      <span>
        <img src="@/asset/icon/overview/todo/index.png" class="icon" />
        待办事项</span
      >
    </div>
    <div class="content">
      <div class="item-box" v-for="item in list" :key="item.key">
        <div class="item" @click="$router.push(item.page)">
          <img class="icon" :src="require(`@/asset/icon/overview/todo/${item.icon}.png`)" alt="" />
          <div class="item-data">
            <div class="value" v-if="item.key">{{ data[item.key] }}</div>
            <div class="label" :style="`${!Boolean(item.key) && 'font-size:17px;'}`">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        // {
        //   name: '商品即将到期',
        //   key: 'storeExpireCount',
        //   icon: 'overdue',
        //   page: '/product/partnerProduct/normalProductlist/list'
        // },
        // {
        //   name: '商品库存预警',
        //   key: 'stockWarnCount',
        //   icon: 'warning',
        //   page: '/product/partnerProduct/normalProductlist/list'
        // },
        // {
        //   name: '待处理退款申请',
        //   key: 'refundApplyCount',
        //   icon: 'service',
        //   page: '/financial/refundApply'
        // },
        // {
        //   name: '订单列表',
        //   icon: 'order',
        //   page: '/order/list'
        // },
        // {
        //   name: '商品列表',
        //   icon: 'product',
        //   page: '/product/partnerProduct/normalProductlist/list'
        // },
        // {
        //   name: '商家管理',
        //   icon: 'store',
        //   page: '/vendor/vendorMange/list'
        // }
        {
          name: '待核销',
          key: 'checkCodeNotUse',
          icon: 'check',
          page: '/order/list'
        },
        {
          name: '佣金提现申请',
          key: 'distributionWithdrawApply',
          icon: 'apply',
          page: '/distribution/withdrawVerify'
        },
        {
          name: '商品库存预警',
          key: 'productStockWarn',
          icon: 'warning',
          page: '/product/partnerProduct/normalProductlist/list'
        },
        {
          name: '商家提现申请',
          key: 'businessWithdrawApply',
          icon: 'businessApply',
          page: '/financial/WithdrawApply'
        },
        {
          name: '商品即将到期',
          key: 'productExpires',
          icon: 'overdue',
          page: '/product/partnerProduct/normalProductlist/list'
        },
        {
          name: '售后待处理',
          key: 'afterSaleApply',
          icon: 'service',
          page: '/order/list'
        },
        {
          name: '待发货',
          key: 'waitDeliver',
          icon: 'deliver',
          page: '/order/list'
        }
      ],
      data: {}
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get('/boom-center-statistics-service/sysAdmin/homestatistic/pending').then(res => {
        this.data = res
      })
    }
  }
}
</script>

<style lang='less' scoped>
.todo {
  .header {
    position: relative;
    background: transparent;
    width: 50%;
    border-radius: 15px 50% 0 0;
    padding: 15px;
    padding-bottom: 0;
    font-size: 17px;
    font-weight: bold;
    color: rgba(71, 72, 73, 1);
    overflow: visible;
    white-space: nowrap;
    .shape {
      position: absolute;
      z-index: 9;
      bottom: -2px;
      left: 0;
      height: 100%;
      width: calc(100% + 30px);
    }
    span {
      display: flex;
      align-items: center;
      .icon {
        width: 30px;
        height: auto;
        margin-right: 10px;
      }
      position: relative;
      z-index: 10;
    }
  }
  .content {
    background: #fff;
    box-shadow: 0px 4px 12px -4px rgba(179, 181, 186, 0.08);
    border-radius: 0 15px 15px 15px;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 15px 0 0;
    .item-box {
      width: 50%;
      padding-left: 15px;
    }
    .item {
      display: flex;
      align-items: center;
      background: rgba(245, 247, 251, 1);
      padding: 15px;
      margin-bottom: 15px;
      border-radius: 10px;
      user-select: none;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        box-shadow: 2px 4px 12px -4px rgba(179, 181, 186, 0.3);
      }
      &:active {
        transform: scale(0.9);
        filter: brightness(95%);
      }
      .icon {
        width: 35px;
        height: auto;
        margin-right: 15px;
        overflow: hidden;
      }
      .item-data {
        flex: 1;
        overflow: hidden;
        .value {
          font-size: 18px;
          font-weight: bold;
          font-family: HarmonyOS Sans SC;
        }
        .label {
          font-size: 13px;
          color: rgba(71, 72, 73, 1);
          font-family: HarmonyOS Sans SC;
          margin-top: 5px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
</style>